import React from 'react';
import Helmet from 'react-helmet';
import { graphql } from 'gatsby';
//import PropTypes from 'prop-types';

export default ({ node, site, isBlogPost }) => {
  const meta = node.yoast_meta;
  const path = node.path;
  const siteUrl = site ? site.siteUrl : '';
  const socialDefaults = meta.yoast_wpseo_social_defaults;

  // Post Featured Image
  const postImage =
    isBlogPost && node.featured_media
      ? `${node.featured_media.localFile.childCloudinaryAsset.fluid.src}`
      : null;

  // Site
  const siteName = meta.yoast_wpseo_website_name;
  const siteTitle = meta.yoast_wpseo_title
    ? meta.yoast_wpseo_title
    : `${node.title} | ${siteName}`;
  const siteDesc = meta.yoast_wpseo_metadesc
    ? meta.yoast_wpseo_metadesc
    : 'We partner.';
  const siteImage = postImage
    ? postImage
    : socialDefaults.og_default_image.localFile.childCloudinaryAsset
    ? `${socialDefaults.og_default_image.localFile.childCloudinaryAsset.fixed.src}`
    : `${socialDefaults.og_default_image.localFile.publicURL}`;

  // Twitter
  const twitterTitle = meta.yoast_wpseo_twitter_title
    ? meta.yoast_wpseo_twitter_title
    : siteTitle;
  const twitterImg = meta.yoast_wpseo_twitter_image
    ? `${meta.yoast_wpseo_twitter_image.localFile.childCloudinaryAsset.fixed.src}`
    : postImage
    ? postImage
    : siteImage;
  const twitterDesc = meta.yoast_wpseo_twitter_description
    ? meta.yoast_wpseo_twitter_description
    : siteDesc;
  const twitterSite = socialDefaults.twitter_site
    ? `@${socialDefaults.twitter_site}`
    : '@firewoodmkt';
  const twitterCard = socialDefaults.twitter_card_type
    ? socialDefaults.twitter_card_type
    : 'summary_large_image';

  // OG
  const ogTitle = meta.yoast_wpseo_facebook_title
    ? meta.yoast_wpseo_facebook_title
    : siteTitle;
  const ogType = isBlogPost ? 'article' : 'website';
  const ogUrl = isBlogPost ? `${siteUrl}/news${path}` 
    : meta.yoast_wpseo_social_url
      ? meta.yoast_wpseo_social_url
      : 'https://firewoodmarketing.com/';
  const ogImg = meta.yoast_wpseo_facebook_image
    ? `${meta.yoast_wpseo_facebook_image.localFile.childCloudinaryAsset.fixed.src}`
    : postImage
    ? postImage
    : siteImage;
  const ogDesc = meta.yoast_wpseo_facebook_description
    ? meta.yoast_wpseo_facebook_description
    : siteDesc;

  return (
    <React.Fragment>
      <Helmet>
        <meta name="description" content={siteDesc} />

        {/* Schema.org markup for Google+ */}
        <meta itemProp="name" content={siteTitle} />
        <meta itemProp="description" content={siteDesc} />
        <meta itemProp="image" content={siteImage} />

        {/* Twitter Card data */}
        <meta name="twitter:card" content={twitterCard} />
        <meta name="twitter:site" content={twitterSite} />
        <meta name="twitter:title" content={twitterTitle} />
        <meta name="twitter:description" content={twitterDesc} />
        <meta name="twitter:creator" content={twitterSite} />
        <meta name="twitter:image:src" content={twitterImg} />

        {/* Open Graph data */}
        <meta property="og:title" content={ogTitle} />
        <meta property="og:type" content={ogType} />
        <meta property="og:url" content={ogUrl} />
        <meta property="og:image" content={ogImg} />
        <meta property="og:description" content={ogDesc} />
        <meta property="og:site_name" content={siteName} />
      </Helmet>
    </React.Fragment>
  );
};

export const seoQuery = graphql`
  fragment SEO on Node {
    __typename
    ... on wordpress__CATEGORY {
      yoast_meta {
        yoast_wpseo_title
        yoast_wpseo_metadesc
        yoast_wpseo_social_defaults {
          twitter
          twitter_site
          twitter_card_type
          opengraph
          og_default_image {
            localFile {
              publicURL
            }
          }
        }
      }
    }
    ... on wordpress__TAG {
      yoast_meta {
        yoast_wpseo_title
        yoast_wpseo_metadesc
        yoast_wpseo_social_defaults {
          twitter
          twitter_site
          twitter_card_type
          opengraph
          og_default_image {
            localFile {
              publicURL
            }
          }
        }
      }
    }
    ... on wordpress__PAGE {
      yoast_meta {
        yoast_wpseo_title
        yoast_wpseo_metadesc
        yoast_wpseo_canonical
        yoast_wpseo_social_url
        yoast_wpseo_company_name
        yoast_wpseo_company_logo {
          localFile {
            childCloudinaryAsset{
              fixed{
                src
              }
            }
          }
        }
        yoast_wpseo_website_name
        yoast_wpseo_facebook_type
        yoast_wpseo_facebook_title
        yoast_wpseo_facebook_description
        yoast_wpseo_facebook_image {
          localFile {
            childCloudinaryAsset{
              fixed{
                src
              }
            }
          }
        }
        yoast_wpseo_twitter_title
        yoast_wpseo_twitter_description
        yoast_wpseo_twitter_image {
          localFile {
            childCloudinaryAsset{
              fixed{
                src
              }
            }
          }
        }
        yoast_wpseo_social_defaults {
          twitter
          twitter_site
          twitter_card_type
          opengraph
          og_default_image {
            localFile {
              childCloudinaryAsset{
                fixed{
                  src
                }
              }
            }
          }
        }
      }
    }
    ... on wordpress__POST {
      path
      yoast_meta {
        yoast_wpseo_title
        yoast_wpseo_metadesc
        yoast_wpseo_canonical
        yoast_wpseo_social_url
        yoast_wpseo_company_name
        yoast_wpseo_company_logo {
          localFile {
            childCloudinaryAsset{
              fixed{
                src
              }
            }
          }
        }
        yoast_wpseo_website_name
        yoast_wpseo_facebook_type
        yoast_wpseo_facebook_title
        yoast_wpseo_facebook_description
        yoast_wpseo_facebook_image {
          localFile {
            childCloudinaryAsset{
              fixed{
                src
              }
            }
          }
        }
        yoast_wpseo_twitter_title
        yoast_wpseo_twitter_description
        yoast_wpseo_twitter_image {
          localFile {
            childCloudinaryAsset{
              fixed{
                src
              }
            }
          }
        }
        yoast_wpseo_social_defaults {
          twitter
          twitter_site
          twitter_card_type
          opengraph
          og_default_image {
            localFile {
              childCloudinaryAsset{
                fixed{
                  src
                }
              }
            }
          }
        }
      }
    }
    ... on wordpress__wp_firewood_portfolio {
      yoast_meta {
        yoast_wpseo_title
        yoast_wpseo_metadesc
        yoast_wpseo_canonical
        yoast_wpseo_social_url
        yoast_wpseo_company_name
        yoast_wpseo_company_logo {
          localFile {
            childCloudinaryAsset{
              fixed{
                src
              }
            }
          }
        }
        yoast_wpseo_website_name
        yoast_wpseo_facebook_type
        yoast_wpseo_facebook_title
        yoast_wpseo_facebook_description

        yoast_wpseo_twitter_title
        yoast_wpseo_twitter_description
        yoast_wpseo_social_defaults {
          twitter
          twitter_site
          twitter_card_type
          opengraph
          og_default_image {
            localFile {
              childCloudinaryAsset{
                fixed{
                  src
                }
              }
            }
          }
        }
      }
    }
  }
`;
